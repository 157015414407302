@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: light;
  --safe-area-top: env(safe-area-inset-top, 0px);
  --safe-area-bottom: env(safe-area-inset-bottom, 0px);
  --safe-area-left: env(safe-area-inset-left, 0px);
  --safe-area-right: env(safe-area-inset-right, 0px);
}

[data-mantine-color-scheme='dark'] {
  color-scheme: dark;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

:root[data-mantine-color-scheme='light'] {
  /* --mantine-color-body: var(--mantine-color-gray-1); */
  --mantine-color-body: white;
}

:root[data-mantine-color-scheme='dark'] {
  --mantine-color-body: var(--mantine-color-dark-8);
}

.imgLight {
  display: unset;
}

.imgDark {
  display: none;
}

[data-mantine-color-scheme='dark'] .imgLight {
  display: none;
}

[data-mantine-color-scheme='dark'] .imgDark {
  display: unset;
}

.animate-bounce-right {
  animation: bounce-right 1s infinite;
}

@keyframes bounce-right {
  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-wiggle-more {
  animation: wiggle-more 6s ease-in-out infinite;
}

@keyframes wiggle-more {
  0%,
  100% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-1deg);
  }
}
